import { PASSWORD_REGEX } from '../regexConstants'

export default [
  {
    name: 'password',
    label: 'Password',
    errorMessage: 'Enter password (min 6 characters)',
    required: true,
    type: 'password',
    pattern: PASSWORD_REGEX,
  },
  {
    name: 'confirmPassword',
    label: 'Re-enter Password',
    errorMessage: 'Re-enter password (should be same as above)',
    required: true,
    type: 'password',
    pattern: PASSWORD_REGEX,
  },
]
