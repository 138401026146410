import { EMAIL_REGEX } from '../regexConstants'

export default [
  {
    name: 'email',
    label: 'Email ',
    errorMessage: 'Enter your email address',
    required: true,
    type: 'text',
    pattern: EMAIL_REGEX,
  },
]
