import React from 'react'
import { useRouter } from 'next/router'
import {
  Heading,
  useToast,
  useColorMode,
  InputRightElement,
  IconButton,
} from '@chakra-ui/react'
import { FormControl, FormErrorMessage, Box } from '@chakra-ui/react'
import { InfoOutlineIcon } from '@chakra-ui/icons'
import { FaEye, FaEyeSlash } from 'react-icons/fa'

import CustomButton from '@/components/Button'
import { TextInput } from '@/components/Input'
import useForm from '@/hooks/useForm'
import formdata from '@/lib/constants/formData/resetPassword'
import LEColorConstants, { bgThemeColor } from '@/lib/constants/colorConstants'
import { SET_NEW_PASSWORD, SET } from '@/lib/constants/authConstants'
import { axiosInstance } from '@/lib/helpers'

const ResetPassword = () => {
  const { colorMode } = useColorMode()
  const router = useRouter()
  const toast = useToast()
  const token = router.query.token as string

  const [showPassword, setShowPassword] = React.useState(false)

  React.useEffect(() => {
    const routeName = router.pathname.split('/')[2]
    if (routeName === 'reset-password' && !token) router.push('/login')
  }, [])

  const { handleChange, inputTypes, handleSubmit, errors, loading } = useForm({
    inputs: formdata,
    cb: async inputs => {
      if (inputTypes.password !== inputTypes.confirmPassword) {
        return toast({
          title: 'Passwords do not match',
          description: 'Please check your password',
          status: 'error',
          variant: 'top-accent',
          isClosable: true,
        })
      }

      await axiosInstance.patch('/auth/admin/reset_password/' + token, {
        password: inputs.password,
      })
      toast({
        title: `Password reset successfully`,
        description: 'You can now login with your new password',
        status: 'success',
        variant: 'top-accent',
        isClosable: true,
      })

      router.push('/login')
    },
  })

  return (
    <Box
      bg={bgThemeColor[colorMode]}
      borderRadius={['5px', '10px', '20px', '30px']}
      px={{ base: '30', md: '62' }}
      py="45"
      mx="auto"
      boxShadow={{ base: '0px 4px 40px 3px rgba(0, 0, 0, 0.1)', md: 'none' }}
    >
      <Heading
        fontSize={{ base: '1rem', md: '1.3rem' }}
        textTransform="capitalize"
        mb="2.9rem"
      >
        {SET_NEW_PASSWORD}
      </Heading>
      <form action="post">
        <Box marginBottom="15px">
          {formdata.map(data => {
            return (
              <FormControl
                isInvalid={errors[data.name]}
                key={`reset_form_${data.label}`}
              >
                <TextInput
                  name={data.name}
                  value={inputTypes[data.name]}
                  formControlProps={{
                    pt: 8,
                  }}
                  handleChange={handleChange}
                  type={
                    data.type === 'password' && showPassword
                      ? 'text'
                      : data.type
                  }
                  placeholder={data.label}
                  TextInputProps={{
                    variant: 'filled',
                    borderRadius: '5px',
                    h: { base: '2.8rem', md: '3.75rem' },
                    _focusWithin: {
                      border: !errors[data.name]
                        ? `2px solid ${LEColorConstants.primary}`
                        : `2px solid ${LEColorConstants.secondary}`,
                    },
                  }}
                  TextInputElement={
                    <>
                      {data['type'] === 'password' ? (
                        <InputRightElement
                          h={{ base: '2.8rem', md: '3.75rem' }}
                          width="3.5rem"
                        >
                          <IconButton
                            onClick={() => setShowPassword(!showPassword)}
                            aria-label="Toggle password"
                            _focus={{ boxShadow: 'none' }}
                            icon={showPassword ? <FaEyeSlash /> : <FaEye />}
                          />
                        </InputRightElement>
                      ) : (
                        <Box />
                      )}
                    </>
                  }
                />

                {errors[data.name] && (
                  <FormErrorMessage fontSize="12px">
                    <InfoOutlineIcon mr="2" />
                    {data.errorMessage}
                  </FormErrorMessage>
                )}
              </FormControl>
            )
          })}
        </Box>

        <CustomButton
          isLoading={loading}
          variant="outline"
          mt={'3rem'}
          onClick={handleSubmit}
          fontSize="1rem"
          borderRadius={'5px'}
          fontWeight="medium"
          h={{ base: '2.5rem', md: '3.12rem' }}
        >
          {SET}
        </CustomButton>
      </form>
    </Box>
  )
}

export default ResetPassword
