import { useState, useEffect } from 'react'
import {
  HStack,
  Text,
  Box,
  useColorModeValue,
  Flex,
  Heading,
  VStack,
} from '@chakra-ui/react'
import CustomImage from '@/components/Image'
import { authSlides } from '@/lib/constants/authCarouselConstants'

function AuthCarousel() {
  const headingColor = useColorModeValue('primary', 'primaryDark')
  const activeSlideDotColor = useColorModeValue('blackAlpha.800', 'white')
  const inactiveSlideDotColor = useColorModeValue('blackAlpha.500', 'gray.500')

  const [currentSlide, setCurrentSlide] = useState(0)

  useEffect(() => {
    const changeSlide = setInterval(() => nextSlide(), 5000)
    return () => clearInterval(changeSlide)
  }, [])

  const slidesCount = authSlides.length

  const nextSlide = () => {
    setCurrentSlide(s => (s === slidesCount - 1 ? 0 : s + 1))
  }
  const setSlide = (slide: any) => {
    setCurrentSlide(slide)
  }
  const carouselStyle = {
    transition: 'all .5s',
    ml: `-${currentSlide * 100}%`,
  }

  return (
    <Flex
      w="full"
      position="relative"
      alignItems="center"
      justifyContent="center"
    >
      <Flex w="full" overflow="hidden">
        <Flex w="full" {...carouselStyle}>
          {authSlides.map((slide, sid) => (
            <Box
              key={`slide-${sid}`}
              boxSize="full"
              justifyContent="center"
              flex="none"
              display="flex"
            >
              <VStack>
                <CustomImage
                  src={slide.img}
                  alt="carousel image"
                  width={192}
                  height={140}
                  fill
                  mb="2.5rem"
                />

                <VStack justifyContent="center" alignItems="center">
                  <Heading
                    color={headingColor}
                    fontSize={{ base: '1.2rem', md: '1.5rem' }}
                    fontWeight="medium"
                    mb="0.5rem"
                  >
                    {slide.title}
                  </Heading>
                  <Text
                    maxW="17.5rem"
                    opacity={0.6}
                    align="center"
                    fontSize="12"
                  >
                    {slide.desc}
                  </Text>
                </VStack>
              </VStack>
            </Box>
          ))}
        </Flex>
      </Flex>
      <HStack justify="center" pos="absolute" bottom="-30px" w="full">
        {Array.from({ length: slidesCount }).map((_, slide) => (
          <Box
            key={`dots-${slide}`}
            cursor="pointer"
            w="15px"
            h="7px"
            m="0 2px"
            bg={
              currentSlide === slide
                ? activeSlideDotColor
                : inactiveSlideDotColor
            }
            display="inline-block"
            transition="background-color 0.6s ease"
            _hover={{ bg: 'blackAlpha.800' }}
            onClick={() => setSlide(slide)}
          ></Box>
        ))}
      </HStack>
    </Flex>
  )
}

export default AuthCarousel
