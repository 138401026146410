import {
  EMAIL_REGEX,
  FREE_TEXT_REGEX,
  FULL_NAME_REGEX,
  PASSWORD_REGEX,
  VERIFICATION_CODE_REGEX,
} from '../regexConstants'

export default [
  {
    name: 'name',
    label: 'Full Name',
    errorMessage: 'Enter your full name',
    required: true,
    type: 'text',
    pattern: FULL_NAME_REGEX,
    level: 1,
  },
  {
    name: 'email',
    label: 'Email',
    errorMessage: 'Enter your email address',
    required: true,
    type: 'text',
    pattern: EMAIL_REGEX,
    level: 1,
  },
  {
    name: 'verificationCode',
    label: 'Verification Code',
    errorMessage: 'Enter verification',
    required: true,
    type: 'text',
    pattern: VERIFICATION_CODE_REGEX,
    level: 1,
  },
  {
    name: 'password',
    label: 'Password',
    errorMessage: 'Enter password (min 6 characters)',
    required: true,
    type: 'password',
    pattern: PASSWORD_REGEX,
    level: 2,
  },
  {
    name: 'confirmPassword',
    label: 'Re-enter Password',
    errorMessage: 'Re-enter password (should be same as above)',
    required: true,
    type: 'password',
    pattern: PASSWORD_REGEX,
    level: 2,
  },
]
