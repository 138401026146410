import React from 'react'
import { useRouter } from 'next/router'
import { observer } from 'mobx-react-lite'
import {
  Heading,
  useToast,
  useColorMode,
  Flex,
  InputRightElement,
  IconButton,
} from '@chakra-ui/react'
import { InfoOutlineIcon } from '@chakra-ui/icons'
import { FaEye, FaEyeSlash } from 'react-icons/fa'
import { FormControl, FormErrorMessage, Box } from '@chakra-ui/react'

import CustomButton from '@/components/Button'
import { TextInput } from '@/components/Input'
import useForm from '@/hooks/useForm'
import formdata from '@/lib/constants/formData/login'
import { APP_NAME } from '@/lib/constants/appConstants'
import LEColorConstants, { bgThemeColor } from '@/lib/constants/colorConstants'
import { CustomLink } from '@/components/Link'
import {
  FORGOT_PASSWORD,
  LOGIN,
  NO_ACCOUNT,
  SIGN_UP,
} from '@/lib/constants/authConstants'
import { useStores } from '@/stores/index'

const Login: React.FC = observer(() => {
  const { auth } = useStores()
  const { colorMode } = useColorMode()
  const router = useRouter()
  const toast = useToast()

  const [showPassword, setShowPassword] = React.useState(false)

  const { handleChange, inputTypes, handleSubmit, errors, loading } = useForm({
    inputs: formdata,
    cb: async inputs => {
      await auth.adminEmailAndPasswordLogin({
        email: inputs.email,
        password: inputs.password,
      })

      toast({
        title: 'Success',
        description: 'Login was successful. Redirecting to Dashboard...',
        status: 'success',
        variant: 'top-accent',
        isClosable: true,
      })

      // get return url from query parameters or default to '/'
      const returnUrl = router.query.returnUrl || '/dashboard'
      router.push(returnUrl as string)
    },
  })

  return (
    <Box
      bg={bgThemeColor[colorMode]}
      borderRadius={['5px', '10px', '20px', '30px']}
      px={{ base: '30', md: '62' }}
      py={'45'}
      maxW="35rem"
      mx="auto"
      boxShadow={{ base: '0px 4px 40px 3px rgba(0, 0, 0, 0.1)', md: 'none' }}
    >
      <Heading
        fontSize={{ base: '1.1rem', md: '1.37rem' }}
        mb={{ base: '1rem', md: '2rem' }}
      >
        Welcome to {APP_NAME}
      </Heading>
      <form action="post">
        <Box marginBottom="15px">
          {formdata.map((data, i) => (
            <FormControl isInvalid={errors[data.name]} key={`sign_in_${i}`}>
              <TextInput
                name={data.name}
                labelProps={{
                  fontSize: '1rem',
                  opacity: 0.6,
                }}
                value={inputTypes[data.name]}
                formControlProps={{
                  pt: '2rem',
                }}
                handleChange={handleChange}
                type={
                  data.type === 'password' && showPassword ? 'text' : data.type
                }
                placeholder={data.label}
                TextInputProps={{
                  variant: 'filled',
                  borderRadius: '5px',
                  h: { base: '2.8rem', md: '3.75rem' },
                  _focusWithin: {
                    border:
                      !errors[data.name] &&
                      `2px solid ${LEColorConstants.primary}`,
                  },
                }}
                TextInputElement={
                  data['type'] === 'password' ? (
                    <InputRightElement
                      h={{ base: '2.8rem', md: '3.7rem' }}
                      width="3.5rem"
                    >
                      <IconButton
                        onClick={() => setShowPassword(!showPassword)}
                        aria-label="Toggle password"
                        _focus={{ boxShadow: 'none' }}
                        icon={showPassword ? <FaEyeSlash /> : <FaEye />}
                      />
                    </InputRightElement>
                  ) : (
                    <Box />
                  )
                }
                error={errors[data.name] && data.errorMessage}
              />
            </FormControl>
          ))}
        </Box>
        <Flex mb="4" justifyContent="flex-end">
          <CustomLink
            href="/forgot-password"
            alignSelf="flex-end"
            opacity={0.6}
            fontSize="1rem"
          >
            {FORGOT_PASSWORD}
          </CustomLink>
        </Flex>

        <CustomButton
          isLoading={loading}
          mt="2.6rem"
          onClick={handleSubmit}
          h={{ base: '2.5rem', md: '3.12rem' }}
          borderRadius={'5px'}
          fontSize="1rem"
          fontWeight="medium"
        >
          {LOGIN}
        </CustomButton>
      </form>
      <Flex justify="center" mt="8">
        <Box fontWeight="medium" opacity={0.6}>
          {NO_ACCOUNT}{' '}
          <CustomLink color="primary" href="/signup">
            {SIGN_UP}
          </CustomLink>
        </Box>
      </Flex>
    </Box>
  )
})

export default Login
