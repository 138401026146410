import React, { useState } from 'react'
import { useRouter } from 'next/router'
import { Tabs, TabPanels, TabPanel } from '@chakra-ui/react'

import { AuthenticationProps } from './types'
import Login from './Login'
import SignUp from './SignUp'
import ForgotPassword from './ForgotPassword'
import ResetPassword from './ResetPassword'

const pageMap = {
  login: 0,
  signup: 1,
  forgotPassword: 2,
  resetPassword: 3,
}

const pageIndexArr = ['login', 'signup', 'forgotPassword', 'resetPassword']

const AuthTab: React.FC<AuthenticationProps> = ({ type }) => {
  const router = useRouter()
  const [tabIndex, setTabIndex] = useState(pageMap[type])

  const handleTabsChange = (index: any) => {
    setTabIndex(index)
    router.push(`/${pageIndexArr[index]}`)
  }

  return (
    <Tabs mt={2} index={tabIndex} onChange={handleTabsChange} mx="auto">
      <TabPanels>
        <TabPanel padding={0}>
          <Login />
        </TabPanel>
        <TabPanel padding={0}>
          <SignUp />
        </TabPanel>
        <TabPanel padding={0}>
          <ForgotPassword />
        </TabPanel>
        <TabPanel padding={0}>
          <ResetPassword />
        </TabPanel>
      </TabPanels>
    </Tabs>
  )
}

export default AuthTab
