import React from 'react'
import { Heading, useToast, useColorMode, Flex } from '@chakra-ui/react'
import { FormControl, Box } from '@chakra-ui/react'

import CustomButton from '@/components/Button'
import { TextInput } from '@/components/Input'
import useForm from '@/hooks/useForm'
import formdata from '@/lib/constants/formData/forgotPassword'
import LEColorConstants, { bgThemeColor } from '@/lib/constants/colorConstants'
import { CustomLink } from '@/components/Link'
import {
  SIGN_UP,
  SIGN_IN,
  FORGOT_PASSWORD,
  SUBMIT,
} from '@/lib/constants/authConstants'
import { axiosInstance } from '@/lib/helpers'

const ForgotPassword = () => {
  const { colorMode } = useColorMode()
  const toast = useToast()

  const { handleChange, inputTypes, handleSubmit, errors, loading } = useForm({
    inputs: formdata,
    cb: async inputs => {
      await axiosInstance.post('/auth/admin/forgot_password', {
        email: inputs.email,
      })

      toast({
        title: 'Password reset link sent',
        description: 'Please check your email for the link',
        status: 'success',
        variant: 'top-accent',
        isClosable: true,
      })
    },
  })

  return (
    <Box
      bg={bgThemeColor[colorMode]}
      borderRadius={['5px', '10px', '20px', '30px']}
      px={{ base: '30', md: '62' }}
      py="45"
      mx="auto"
      boxShadow={{ base: '0px 4px 40px 3px rgba(0, 0, 0, 0.1)', md: 'none' }}
    >
      <Heading
        fontSize={{ base: '1rem', md: '1.3rem' }}
        textTransform="capitalize"
        mb={{ base: '1rem', md: '2.9rem' }}
      >
        {FORGOT_PASSWORD}
      </Heading>
      <form action="post">
        <Box marginBottom="15px">
          {formdata.map(data => {
            return (
              <FormControl
                isInvalid={errors[data.name]}
                key={`forgot_form_${data.label}`}
              >
                <TextInput
                  name={data.name}
                  value={inputTypes[data.name]}
                  formControlProps={{
                    pt: 8,
                  }}
                  handleChange={handleChange}
                  type={data.type}
                  placeholder={data.label}
                  TextInputProps={{
                    variant: 'filled',
                    borderRadius: '5px',
                    h: { base: '2.8rem', md: '3.75rem' },
                    _focusWithin: {
                      border:
                        !errors[data.name] &&
                        `2px solid ${LEColorConstants.primary}`,
                    },
                  }}
                  error={errors[data.name] && data.errorMessage}
                />
              </FormControl>
            )
          })}
        </Box>

        <CustomButton
          isLoading={loading}
          variant="outline"
          mt={'3rem'}
          onClick={handleSubmit}
          fontSize="1rem"
          fontWeight={'medium'}
          borderRadius={'5px'}
          h={{ base: '2.5rem', md: '3.12rem' }}
        >
          {SUBMIT}
        </CustomButton>
        {/* )} */}
      </form>
      <Flex justify="center" mt="8">
        <CustomLink color="primary" href="/signup">
          {SIGN_UP}
        </CustomLink>
        <Box fontWeight="medium" opacity={0.6} mx="0.3rem">
          {' or '}
        </Box>
        <CustomLink color="primary" href="/login">
          {SIGN_IN}
        </CustomLink>
      </Flex>
    </Box>
  )
}

export default ForgotPassword
