import React from 'react'
import { Box, Flex, useColorMode } from '@chakra-ui/react'

import NavBar from '@/components/home/NavBar'
import { authBGColor } from '@/lib/constants/colorConstants'

import AuthCarousel from './AuthCarousel'
import AuthTab from './AuthTab'
import { AuthenticationProps } from './types'

const Authentication: React.FC<AuthenticationProps> = ({ type }) => {
  const { colorMode } = useColorMode()

  return (
    <Box as="section" bg={authBGColor[colorMode]}>
      <NavBar menus={[]} canStick={false} />
      <Flex pb="7rem">
        <Flex mt="5rem" mb="5rem" w="100%" mx="auto" h="full" p="1.5rem">
          <Flex
            width="50%"
            display={['none', 'none', 'flex', 'flex']}
            justifyContent="center"
            alignItems="center"
          >
            <AuthCarousel />
          </Flex>
          <Box width={['100%', '70%', '50%', '50%']} mx="auto">
            <Flex
              width="full"
              height="full"
              justifyContent="center"
              alignItems={'center'}
              px={2}
              mx="auto"
            >
              <Box
                borderRadius={{ base: '8px', md: '20px' }}
                w="full"
                mx="auto"
              >
                <AuthTab type={type} />
              </Box>
            </Flex>
          </Box>
        </Flex>
      </Flex>
    </Box>
  )
}

export default Authentication
