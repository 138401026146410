import React from 'react'
import { useRouter } from 'next/router'
import {
  Heading,
  useToast,
  useColorMode,
  Flex,
  InputRightElement,
  IconButton,
} from '@chakra-ui/react'
import { FormControl, Box } from '@chakra-ui/react'
import { FaEye, FaEyeSlash } from 'react-icons/fa'

import CustomButton from '@/components/Button'
import { TextInput } from '@/components/Input'
import useForm from '@/hooks/useForm'
import formdata from '@/lib/constants/formData/signup'
import LEColorConstants, {
  bgThemeColor,
  basicTextColor,
} from '@/lib/constants/colorConstants'
import { CustomLink } from '@/components/Link'
import {
  HAVE_ACCOUNT,
  SIGN_UP,
  SIGN_IN,
  NEXT,
  FINISH,
} from '@/lib/constants/authConstants'
import { axiosInstance, handleErrorResponse, setToken } from '@/lib/helpers'

const Signup = () => {
  const { colorMode } = useColorMode()
  const router = useRouter()
  const toast = useToast()

  const [showOtpInput, setShowOtpInput] = React.useState(false)
  const [loadingOtp, setLoadingOtp] = React.useState(false)
  const [showPassword, setShowPassword] = React.useState(false)
  const [step, setStep] = React.useState(1)

  React.useEffect(() => {
    // Runs if form step changes to 2
    if (step === 2) {
      const newFormData = formdata
        .filter(form => form.level === 2)
        .reduce((prev, curr) => ({ ...prev, [curr.name]: '' }), {})

      handleModChange(newFormData)
    }
  }, [step])

  const {
    handleChange,
    handleModChange,
    inputTypes,
    handleSubmit,
    errors,
    loading,
  } = useForm({
    inputs:
      step === 1
        ? formdata.slice(0, showOtpInput ? 3 : 2)
        : formdata.filter(form => form.level === step),
    cb: async inputs => {
      if (step == 1) {
        console.log(inputs)
        await axiosInstance.post('/auth/admin/otp_register', {
          email: inputs.email,
          name: inputs.name,
        })

        toast({
          title: `Sign Up`,
          description: 'Verification code has been sent to you email!',
          status: 'success',
          variant: 'top-accent',
          isClosable: true,
        })
        setShowOtpInput(true)
        return
      }

      // create password
      if (inputTypes.password !== inputTypes.confirmPassword) {
        return toast({
          title: 'Passwords do not match',
          description: 'Please check your password',
          status: 'error',
          variant: 'top-accent',
          isClosable: true,
        })
      }

      await axiosInstance.post('/auth/admin/create_password', {
        password: inputTypes.password,
      })

      toast({
        title: `Success`,
        description:
          'Your password has been created successfully, you can now login!',
        status: 'success',
        variant: 'top-accent',
        isClosable: true,
      })

      router.push('/login')
    },
  })

  const submitOtp = async () => {
    setLoadingOtp(true)

    try {
      const response = await axiosInstance.post('/auth/admin/otp_login', {
        email: inputTypes.email,
        otp: inputTypes.verificationCode,
      })

      const user = response.data.data
      if (user) setToken(user.token)

      setStep(2)
      setShowOtpInput(false)
    } catch (err) {
      handleErrorResponse(err)
    }

    setLoadingOtp(false)
  }

  const handleResendOtp = async () => {
    try {
      const response = await axiosInstance.post(
        '/auth/admin/resend_otp_register',
        {
          email: inputTypes.email,
        }
      )

      toast({
        title: 'OTP Sent',
        description: response.data.message,
        status: 'success',
        variant: 'top-accent',
        isClosable: true,
      })
    } catch (err) {
      handleErrorResponse(err)
    }

    setLoadingOtp(false)
  }

  return (
    <Box
      bg={bgThemeColor[colorMode]}
      borderRadius={['5px', '10px', '20px', '30px']}
      px={{ base: '30', md: '62' }}
      py="45"
      mx="auto"
      boxShadow={{ base: '0px 4px 40px 3px rgba(0, 0, 0, 0.1)', md: 'none' }}
    >
      <Heading
        fontSize={{ base: '1rem', md: '1.3rem' }}
        textTransform="capitalize"
        mb={{ base: '1rem', md: '2.9rem' }}
      >
        {SIGN_UP}
      </Heading>
      <form action="post">
        <Box marginBottom="15px">
          {formdata
            .filter(form => form.level === step)
            .slice(0, showOtpInput ? 3 : !showOtpInput && step == 3 ? 3 : 2)
            .map(data => {
              return (
                <FormControl
                  isInvalid={errors[data.name]}
                  key={`signup_form_${data.label}`}
                >
                  <TextInput
                    name={data.name}
                    value={inputTypes[data.name]}
                    formControlProps={{
                      pt: 8,
                    }}
                    handleChange={handleChange}
                    type={
                      data.type === 'password' && showPassword
                        ? 'text'
                        : data.type
                    }
                    placeholder={data.label}
                    TextInputProps={{
                      variant: 'filled',
                      borderRadius: '5px',
                      h: { base: '2.8rem', md: '3.75rem' },
                      _focusWithin: {
                        border:
                          !errors[data.name] &&
                          `2px solid ${LEColorConstants.primary}`,
                      },
                    }}
                    TextInputElement={
                      <>
                        {data['name'] === 'verificationCode' ? (
                          <InputRightElement
                            h={{ base: '2.8rem', md: '3.5rem' }}
                            width="7rem"
                          >
                            <OTPtimer
                              initialMinute={2}
                              initialSeconds={0}
                              onPress={handleResendOtp}
                            />
                          </InputRightElement>
                        ) : (
                          <Box />
                        )}

                        {data['type'] === 'password' ? (
                          <InputRightElement
                            h={{ base: '2.8rem', md: '3.75rem' }}
                            width="3.5rem"
                          >
                            <IconButton
                              onClick={() => setShowPassword(!showPassword)}
                              aria-label="Toggle password"
                              _focus={{ boxShadow: 'none' }}
                              icon={showPassword ? <FaEyeSlash /> : <FaEye />}
                            />
                          </InputRightElement>
                        ) : (
                          <Box />
                        )}
                      </>
                    }
                    error={errors[data.name] && data.errorMessage}
                  />
                </FormControl>
              )
            })}
        </Box>

        {showOtpInput ? (
          <CustomButton
            isLoading={loadingOtp}
            variant="outline"
            mt={'3rem'}
            onClick={submitOtp}
            fontSize="1rem"
            fontWeight={'medium'}
            borderRadius={'5px'}
            h={{ base: '2.5rem', md: '3.12rem' }}
          >
            {NEXT}
          </CustomButton>
        ) : (
          <CustomButton
            isLoading={loading}
            variant="outline"
            mt={'3rem'}
            onClick={handleSubmit}
            fontSize="1rem"
            fontWeight={'medium'}
            borderRadius={'5px'}
            h={{ base: '2.5rem', md: '3.12rem' }}
          >
            {step === 1 ? 'Submit' : FINISH}
          </CustomButton>
        )}
      </form>
      <Flex justify="center" mt="8">
        <Box fontWeight="medium" opacity={0.6}>
          {HAVE_ACCOUNT}{' '}
          <CustomLink color="primary" href="/login">
            {SIGN_IN}
          </CustomLink>
        </Box>
      </Flex>
    </Box>
  )
}

const OTPtimer = (props: {
  initialMinute: number
  initialSeconds: number
  onPress?: () => void
}) => {
  const { colorMode } = useColorMode()

  const { initialMinute = 0, initialSeconds = 0, onPress = () => {} } = props
  const [minutes, setMinutes] = React.useState(initialMinute)
  const [seconds, setSeconds] = React.useState(initialSeconds)
  const [timeElapse, setTimeElapse] = React.useState(false)
  const [shouldRestart, setShouldRestart] = React.useState(false)

  const timerGen = (seconds: number, minutes: number) => {
    const interval = setInterval(() => {
      // Stop being true
      if (shouldRestart) setShouldRestart(false)

      if (seconds > 0) {
        setSeconds(seconds - 1)
      }
      if (seconds === 0) {
        if (minutes === 0) {
          clearInterval(interval)
          setTimeElapse(true)
        } else {
          setMinutes(minutes - 1)
          setSeconds(59)
        }
      }
    }, 1000)
    return interval
  }

  React.useEffect(() => {
    let myInterval = timerGen(seconds, minutes)

    return () => {
      clearInterval(myInterval)
    }
  }, [seconds])

  React.useEffect(() => {
    let newInterval: NodeJS.Timeout

    if (shouldRestart) {
      newInterval = timerGen(initialSeconds, initialMinute)
    }

    return () => {
      if (newInterval) {
        clearInterval(newInterval)
      }
    }
  }, [shouldRestart])

  const onClick = () => {
    if (timeElapse) {
      // restart time
      setShouldRestart(true)
      onPress()
      setTimeElapse(false)
    }
  }

  return (
    <>
      <CustomButton
        fontSize="12px"
        fontWeight="medium"
        bgColor={'transparent'}
        h="2.378rem"
        w="5rem"
        borderRadius={'5px'}
        onClick={onClick}
        color={basicTextColor[colorMode]}
      >
        {!timeElapse ? (
          <>
            {minutes}:{seconds < 10 ? `0${seconds}` : seconds}
          </>
        ) : (
          <>Resend</>
        )}
      </CustomButton>
    </>
  )
}

export default Signup
