export const authSlides = [
  {
    id: 1,
    title: 'All in one Tool',
    desc: 'Carry out and supervise tests in secconds. \nEasy to use and understand. \nNo boring Jargon \nPay for only what you use.',
    img: '/illustrator-1.svg',
  },
  {
    id: 2,
    title: 'Take 100% control',
    desc: `Assign roles to users to carry out specific tasks and functions.  \nGive instant feedback on candidates' performance, previous scores, the total number of candidates, and full-time taken to complete tests, and assessments.`,
    img: '/illustrator-4.svg',
  },
  {
    id: 3,
    title: 'Be your own artist',
    desc: 'Customize logos, themes, background pictures, content and colors to your taste. \nCreate customized domain names to serve as an extension of your brand and personality.',
    img: '/illustrator-3.svg',
  },
]
